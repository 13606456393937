@mixin respond($breakpoint) {
  @if $breakpoint == laptopXL {
    @media only screen and (width <= 2560px) {
      @content;
    }
  }

  @if $breakpoint == laptopL {
    @media only screen and (width <= 1440px) {
      @content;
    }
  }

  @if $breakpoint == laptopC {
    @media only screen and (width <= 1150px) {
      @content;
    }
  }

  @if $breakpoint == laptop {
    @media only screen and (width <= 1024px) {
      @content;
    }
  }

  @if $breakpoint == tabletL {
    @media only screen and (width <= 920px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (width <= 768px) {
      @content;
    }
  }

  @if $breakpoint == tabletS {
    @media only screen and (width <= 576px) {
      @content;
    }
  }

  @if $breakpoint == mobileL {
    @media only screen and (width <= 425px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (width <= 375px) {
      @content;
    }
  }
}

@mixin rtlAndRespond($breakpoint) {
  body[dir='rtl'] & {
    @include respond($breakpoint) {
      @content;
    }
  }
}

@mixin transition {
  transition: all 0.3s ease;
}

// Typography Mixins
@mixin tTitle_1 {
  font-size: 36px;
  line-height: 44px;
}

@mixin tTitle_1_demibold {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;

  @include respond(tablet) {
    font-size: 32px;
    line-height: 40px;
  }

  @include respond(mobileL) {
    font-size: 26px;
    line-height: 36px;
  }
}

@mixin tTitle_1_semibold {
  font-size: 24px;
  line-height: 32px;
}

@mixin tTitle_2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

@mixin tTitle_2_semibold {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  @include respond(tablet) {
    font-size: 22px;
    line-height: 32px;
  }

  @include respond(mobileL) {
    font-size: 20px;
    line-height: 28px;
  }
}

@mixin tTitle_2_demibold {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  @include respond(tablet) {
    font-size: 24px;
    line-height: 32px;
  }

  @include respond(mobileL) {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.44px;
  }
}

@mixin tTitle_2_medium {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

@mixin tTitle_3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

@mixin tTitle_3-semibold {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

@mixin tSubtitle-3-medium {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

@mixin tTitle_3-demibold {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  @include respond(mobileL) {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.24px;
  }
}

@mixin tTitle_3-medium {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

@mixin tTitle_4-medium {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

@mixin tTitle_3-regular {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

@mixin tButton {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

@mixin tButton_2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.12px;
}

@mixin t404 {
  font-style: normal;
  font-weight: 600;
  font-size: 128px;
  line-height: 128px;

  @include respond(tabletS) {
    font-size: 100px;
    line-height: 100px;
  }
}

@mixin tTitle_1_bold {
  font-weight: 700;
  font-size: 60px;
  line-height: 40px;
  letter-spacing: -0.44px;

  @include respond(mobileL) {
    font-size: 40px;
    line-height: 35px;
  }

  @include respond(mobile) {
    font-size: 32px;
  }
}

@mixin tTitle_1_banner {
  font-weight: 700;
  font-size: 68px;
  line-height: 80px;
}

@mixin tTitle_1_block {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;

  @include respond(laptop) {
    font-size: 48px;
    line-height: 60px;
  }

  @include respond(tablet) {
    font-size: 28px;
    line-height: 36px;
  }
}

@mixin tTitle_1_form {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;

  @include respond(tabletS) {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }
}

@mixin tTitle_2_bold {
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
}

@mixin tTitle_1_main {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
}

@mixin tTitle_2_main {
  font-weight: 700;
  font-size: 42px;
  line-height: 52px;
}

@mixin tTitle_2_Semibold {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}

@mixin tSubtitle {
  font-weight: 400;
  letter-spacing: -0.12px;
  font-size: 16px;
  line-height: 24px;
}

@mixin tSubtitle_Semibold {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.12px;
}

@mixin tSubtitle_Bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 5px;
}

@mixin tCaption {
  font-weight: 400;
  letter-spacing: -0.08px;
  font-size: 14px;
  line-height: 20px;
}

@mixin tCaption_Semibold {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

@mixin tTag {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

@mixin tChatTime {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@mixin text_regular {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

@mixin headline_regular {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

@mixin Land_28 {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}

@mixin Land_32 {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
}

@mixin Label {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.12px;
}

// SHADOWS  SHADOWS  SHADOWS  SHADOWS  SHADOWS  SHADOWS  SHADOWS  SHADOWS  SHADOWS
@mixin sElevation_1_bordered {
  box-shadow:
    0 0 0 1px var(--borderPrimary),
    0 8px 16px rgb(41 41 41 / 4%),
    0 0 6px rgb(41 41 41 / 4%);
}

@mixin sElevation_1 {
  box-shadow:
    0 8px 16px rgb(37 33 41 / 4%),
    0 0 6px rgb(37 33 41 / 4%);
}

@mixin sElevation_3 {
  box-shadow:
    0 8px 16px var(--shadow_2),
    0 0 6px var(--shadow_2);
}

@mixin sElevation_4 {
  box-shadow: 0 0 80px rgb(0 0 0 / 10%);
}

@mixin sElevation_6 {
  box-shadow:
    0 8px 16px rgb(41 41 41 / 4%),
    0 0 6px rgb(41 41 41 / 4%);
}

@mixin sElevation_5 {
  box-shadow:
    0 16px 32px rgb(37 33 41 / 8%),
    0 0 12px rgb(37 33 41 / 4%);
}

@mixin sElevation_7 {
  box-shadow:
    0 0 6px 0 rgb(41 41 41 / 4%),
    0 8px 16px 0 rgb(41 41 41 / 4%);
}

@mixin main_shadow {
  box-shadow:
    0 0 16px 0 rgb(37 33 41 / 4%),
    0 16px 28px 0 rgb(37 33 41 / 4%);
}

// CONTENTS ADAPTIVE PADDINGS

@mixin contentPaddings {
  @include respond(mobileL) {
    padding: 16px;
  }
}

@mixin spinnerAnimation {
  animation: spin 0.75s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }
}

@mixin placeholder {
  &::placeholder {
    @content;
  }
}

@mixin rtl {
  body[dir='rtl'] & {
    @content;
  }
}

@mixin ltr {
  body[dir='ltr'] & {
    @content;
  }
}
