@import "src/_app/styles/mixins.scss";
.error_404 {
  background: var(--bg_2);
  color: var(--primary);
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    border-radius: 48px;
    background: var(--secondary);
    width: 640px;
    height: 488px;
    margin: 168px 64px;

    @include respond(tabletS) {
      width: 343px;
      height: 436px;
      margin: 88px 16px;
    }

    .content {
      padding: 56px 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 28px;

      .title {
        margin: 0;

        @include t404;
      }

      .description {
        @include tTitle_2_demibold;

        @include respond(tabletS) {
          @include tTitle_3;
        }
      }

      .btn {
        width: 277px;

        @include respond(tabletS) {
          width: 100%;
        }
      }
    }

    .footer {
      padding: 32px;
      display: flex;
      justify-content: center;
      border-top: 1px solid var(--border_3);

      @include respond(tabletS) {
        padding: 24px;
      }
    }
  }
}
